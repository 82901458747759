import { FC, useState } from "react"
import styled from "styled-components"
import { ContentBlock, ContentfulImage } from "@lib/types"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"

import Arrow from "@svg/arrow.svg"

interface Slide extends ContentBlock {
  name: string
  slug: string
  excerpt: string
  featuredImage: ContentfulImage
}
interface Props {
  displayArrows?: boolean
  displayNavigation?: boolean
  SlideComponent: FC<any>
  slides: ContentBlock[]
  config?: {}
}

export default function Slider({
  displayArrows,
  displayNavigation,
  slides,
  SlideComponent,
  config,
}: Props): JSX.Element {
  const [mounted, setMounted] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  const [ref, slider] = useKeenSlider<HTMLUListElement>({
    initial: 0,
    mounted: () => setMounted(true),
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
    ...config,
  })

  const slidesPerView = slider && slider.details().slidesPerView

  return (
    <StyledSlider $flex={displayArrows} $visible={mounted}>
      {displayArrows && slider && (
        <ArrowLeft
          className="slider__arrow"
          onClick={() => slider.prev()}
          disabled={currentSlide === 0}
        >
          <Arrow />
        </ArrowLeft>
      )}
      <SliderWrapper className="slider__wrapper">
        <KeenSlider ref={ref} className="keen-slider">
          {slides.map((block: any, i: number) => {
            return (
              <Slide
                className={`keen-slider__slide number-slide${i + 1}`}
                key={`${block.sys.id}${i}`}
              >
                <SlideInner>
                  <SlideComponent data={block} />
                </SlideInner>
              </Slide>
            )
          })}
        </KeenSlider>
        {displayNavigation && slider && (
          <Dots className="keen-slider__dots">
            {[...Array(slider.details().size).keys()].map(idx => {
              return (
                <Dot
                  key={idx}
                  onClick={() => {
                    slider.moveToSlideRelative(idx)
                  }}
                  $isActive={currentSlide === idx}
                />
              )
            })}
          </Dots>
        )}
      </SliderWrapper>
      {displayArrows && slider && (
        <ArrowRight
          className="slider__arrow"
          onClick={() => slider.next()}
          disabled={
            slidesPerView > 1
              ? currentSlide === slides.length / slidesPerView
              : currentSlide === slides.length - 1
          }
        >
          <Arrow />
        </ArrowRight>
      )}
    </StyledSlider>
  )
}

interface SliderProps {
  $flex: boolean | undefined
  $visible: boolean
}
interface ButtonProps {
  $isActive: boolean
}

const StyledSlider = styled.div<SliderProps>`
  position: relative;
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
  width: 100%;
  display: ${({ $flex }) => ($flex ? "flex" : "block")};
  align-items: center;
  justify-content: center;
`

const SliderWrapper = styled.div`
  width: 100%;
  max-width: 100rem;
`

const KeenSlider = styled.ul`
  margin: 0 0 3.6rem;
  max-width: 100rem;
`

const Slide = styled.li``

const SlideInner = styled.div`
  display: grid;
  place-items: center;
`

const Dots = styled.div`
  display: flex;
  justify-content: center;
`

const Dot = styled.button<ButtonProps>`
  display: block;
  margin: 0 0.5rem;
  padding: 0;
  width: 1rem;
  height: 1rem;
  background: ${({ theme, $isActive }) =>
    $isActive ? theme.color.black : theme.color.grey};

  :focus {
    outline: none;
  }
`

const ArrowLeft = styled.button`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: block;
    min-width: 6rem;
    min-height: 6rem;
    padding: 0;
    margin-left: 3rem;
    margin-right: auto;
    background: ${({ theme }) => theme.color.black};
    transition: background-color 0.2s linear;

    &:disabled {
      background: ${({ theme }) => theme.color.grey};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    margin-left: 6rem;
  }
`

const ArrowRight = styled(ArrowLeft)`
  margin-right: 6.3rem;
  margin-left: auto;
  svg {
    transform: rotate(180deg);
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin-right: 3rem;
    margin-left: auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    margin-right: 6rem;
  }
`
