import React, { FC, useEffect, useState, useRef, useLayoutEffect } from "react"
import styled, { css } from "styled-components"
import Link from "next/link"
import { useTracking } from "@hooks/index"

import { ContentfulButton } from "@lib/types"
import { decode } from "shopify-gid"

import { camelize } from "@lib/utils"
import { SpinningLoader } from "@components/ui"
import { useViewportScroll } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { regionToFulfillmentId } from "@lib/config"
import { useRouter } from "next/router"
import { ProductVariant } from "shopify-storefront-api-typings"

interface Props {
  className?: string
  button?: ContentfulButton
  buttonTheme?: string
  loading?: boolean
  href?: string
  target?: boolean
  title?: string
  boldTitle?: boolean
  buttonAlign?: string
  disabled?: boolean
  noAnchor?: boolean // If true, override href and slug and return plain button without Anchor
  onClick?: () => void
  stickToBottom?: boolean
  showOutOfStockMsg?: any
  showLowStock?: any
  selectedVariant?: ProductVariant | null
  quantityAvailable?: any
}

export const Button: FC<Props> = ({
  className,
  onClick,
  disabled,
  loading = false,
  button,
  buttonAlign,
  target = true,
  title: t,
  buttonTheme,
  href: h,
  boldTitle: b,
  noAnchor,
  stickToBottom,
  showOutOfStockMsg,
  selectedVariant,
  showLowStock,
  quantityAvailable,
}) => {
  const theme = button?.theme || buttonTheme
  const href = button?.link?.href || h
  const slug = button?.link?.entry?.slug
  const slugType = button?.link?.entry?.__typename

  const title = button?.link?.title || t
  const boldTitle = button?.link?.boldTitle || b

  const themeName = theme ? camelize(theme) : "black"
  const finalClassname = `${className ? className : ""} ${
    button?.extraClassname ? button?.extraClassname : ""
  }`
  const { track } = useTracking()

  const { ref, inView, entry } = useInView({ threshold: 1, rootMargin: "0px 0px -18px 0px" })
  const [show, setShow] = useState(false)

  const [email, setEmail] = useState("")

  useEffect(() => {
    if (entry) {
      const topPosition = entry.target.getBoundingClientRect().top
      if ((topPosition > 0 && inView) || topPosition < 0) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
  }, [inView])

  const scrollToSizeSelector = () => {
    const sizeSelector = document.getElementById("size-selector")
    const stickyButton = document.getElementById("sticky-addtobag-button")
    if (sizeSelector && stickyButton && window) {
      const offset = window.innerHeight - stickyButton.clientHeight - sizeSelector.clientHeight - 5
      const elementPosition = sizeSelector.getBoundingClientRect().top
      const offsetPosition = elementPosition - offset
      window.scrollBy({
        top: offsetPosition,
        behavior: "smooth",
      })
    }
  }
  const { locale } = useRouter()

  const addBackInStockUser = async () => {
    if (locale) {
      const locationID = regionToFulfillmentId(locale)
      console.log(email, decode(selectedVariant?.id).id, locationID, locale)
      const req = await fetch("/api/back-in-stock/add-back-in-stock-user", {
        method: "POST",
        body: JSON.stringify({
          email,
          variantID: decode(selectedVariant?.id).id,
          locationID,
          locale,
        }),
      })
      const res = await req.json()
      console.log(res)
      if (req.ok) {
        setEmail("")
        //setBackInStockMessage("success")
      }
    }
  }

  if (onClick)
    return (
      <>
        <ButtonWrapper
          $show={show}
          $stickToBottom={stickToBottom}
          ref={ref}
          $buttonAlign={buttonAlign}
          $disabled={disabled}
        >
          <StyledButton
            $show={show}
            $stickToBottom={stickToBottom}
            className={finalClassname}
            onClick={() => {
              onClick()
              track("Button Clicked", {
                type: "Button",
                label: title,
              })
            }}
            disabled={disabled || loading}
            $themeName={themeName}
          >
            {loading && <SpinningLoader />}
            <Title $loading={loading}>{boldTitle ? <b>{title}</b> : <>{title}</>}</Title>
          </StyledButton>
          {showOutOfStockMsg && (
            <>
              <LowStock hideOnStickyMobile>
                <span>Sold out.</span> Please try another selection
              </LowStock>
              <input value={email} onChange={e => setEmail(e.target.value)} type="text" />
              <button onClick={addBackInStockUser}>SEND</button>
            </>
          )}
          {showLowStock && (
            <LowStock hideOnStickyMobile>
              <span>limited stock.</span> {`only ${quantityAvailable} left`}
            </LowStock>
          )}
        </ButtonWrapper>
        {show && stickToBottom && (
          <StickyButtonWrapper
            id="sticky-addtobag-button"
            $buttonAlign={buttonAlign}
            $disabled={disabled}
          >
            <ScrollHandle onClick={scrollToSizeSelector} disabled={!disabled} />
            <StyledButton
              className={finalClassname}
              onClick={() => {
                onClick()
                track("Button Clicked", {
                  type: "Button",
                  label: title,
                })
              }}
              disabled={disabled || loading}
              $themeName={themeName}
            >
              {loading && <SpinningLoader />}
              <Title $loading={loading}>{boldTitle ? <b>{title}</b> : <>{title}</>}</Title>
            </StyledButton>
            {showOutOfStockMsg && (
              <LowStock>
                <span>Sold out.</span> Please try another selection
              </LowStock>
            )}
            {showLowStock && (
              <LowStock>
                <span>limited stock.</span> {`only ${quantityAvailable} left`}
              </LowStock>
            )}
          </StickyButtonWrapper>
        )}
      </>
    )

  // Site links
  if (slug && !noAnchor) {
    const formattedSlug = slugType === "Product" ? `/products/${slug}` : `/${slug}`
    return (
      <ButtonWrapper ref={ref} $buttonAlign={buttonAlign} $disabled={disabled}>
        <Link href={disabled ? "#" : `${formattedSlug}`} passHref>
          <Anchor
            className={className}
            $themeName={themeName}
            onClick={() =>
              track("Button Clicked", {
                type: "Link",
                url: `https://www.hautehijab.com/${slug}`,
                label: title,
              })
            }
          >
            <span>{boldTitle ? <b>{title}</b> : <>{title}</>}</span>
          </Anchor>
        </Link>
      </ButtonWrapper>
    )
  }

  // External links
  if (href && !noAnchor) {
    return (
      <ButtonWrapper ref={ref} $buttonAlign={buttonAlign} $disabled={disabled}>
        {target ? (
          <Anchor
            href={disabled ? "#" : href}
            className={className}
            $themeName={themeName}
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              track("Button Clicked", {
                type: "Link",
                url: href,
                label: title,
              })
            }
          >
            <span>{boldTitle ? <b>{title}</b> : <>{title}</>}</span>
          </Anchor>
        ) : (
          <Anchor
            href={disabled ? "#" : href}
            className={className}
            $themeName={themeName}
            onClick={() =>
              track("Button Clicked", {
                type: "Link",
                url: href,
                label: title,
              })
            }
          >
            <span>{boldTitle ? <b>{title}</b> : <>{title}</>}</span>
          </Anchor>
        )}
      </ButtonWrapper>
    )
  }
  return (
    <ButtonWrapper ref={ref} $buttonAlign={buttonAlign} $disabled={disabled}>
      <StyledButton
        className={finalClassname}
        $themeName={themeName}
        disabled={disabled || loading}
      >
        {loading && <SpinningLoader />}
        <Title $loading={loading}>{boldTitle ? <b>{title}</b> : <>{title}</>}</Title>
      </StyledButton>
    </ButtonWrapper>
  )
}

interface StyleProps {
  $themeName: string
  $show?: boolean
  $stickToBottom?: boolean
}

interface ButtonProps {
  $disabled?: boolean
  $buttonAlign?: string
  ref?: any
  $show?: boolean
  $stickToBottom?: boolean
}

export const ButtonWrapper = styled.div<ButtonProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    justify-content: ${({ $buttonAlign }) =>
      $buttonAlign === "left" ? "flex-start" : $buttonAlign === "right" ? "flex-end" : "center"};
  }

  @media (max-width: ${({ theme }: any) => theme.breakpoint.md}px) {
    ${props =>
      props?.$show &&
      props?.$stickToBottom &&
      `
          visibility: hidden !important;
          pointer-events: none;
          * {
            visibility: hidden !important;
          }
      `}
  }
`

const ScrollHandle = styled.div<any>`
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  ${({ disabled }) => disabled && `display: none; pointer-events: none;`}
`

const buttonUnderlineStyle = css<StyleProps>`
  position: relative;
  border: none;
  text-transform: uppercase;
  padding: 0 0 0.5rem;

  &::after {
    content: "";
    background: ${({ theme, $themeName }) => theme.button[$themeName].borderColor};
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0px;
    transform: scaleX(1);
    transition: transform 0.25s ease-out;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    &:after {
      transform: scaleX(1);
      transition: transform 0.25s ease-out;
    }

    &:hover {
      &:after {
        transform: scaleX(0);
      }
    }
  }
`

export const buttonStyles = css<StyleProps>`
  display: inline-block;
  padding: 1rem 2.5rem;
  border-radius: ${({ theme, $themeName }) => theme.button[$themeName].borderRadius}rem;
  border: 1px solid;
  border-color: ${({ theme, $themeName }) => theme.button[$themeName].borderColor};
  color: ${({ theme, $themeName }) => theme.button[$themeName].color};
  background: ${({ theme, $themeName }) => theme.button[$themeName].background};
  text-decoration: ${({ theme, $themeName }) => theme.button[$themeName].textDecoration};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.185rem;
  line-height: 1.6rem;
  cursor: pointer;
  transition: border-color ease-out 0.25s, color ease-out 0.25s, background ease-out 0.25s;

  b {
    font-weight: 700;
  }

  &:hover {
    border-color: ${({ theme, $themeName }) => theme.button[$themeName].borderColorHover};
    color: ${({ theme, $themeName }) => theme.button[$themeName].colorHover};
    background: ${({ theme, $themeName }) => theme.button[$themeName].backgroundHover};
  }

  &:disabled {
    background: ${({ theme }) => theme.color.greyLight};
    border-color: ${({ theme }) => theme.color.greyLight};
    color: ${({ theme }) => theme.color.grey};
    cursor: auto;
  }

  ${({ $themeName }) => ($themeName.includes("underline") ? buttonUnderlineStyle : null)}
`

const StyledButton = styled.button<StyleProps>`
  position: relative;
  width: fit-content;
  ${buttonStyles}
`

const Anchor = styled.a`
  ${buttonStyles}
`

const Title = styled.span<{ $loading: boolean }>`
  visibility: ${({ $loading }) => ($loading ? "hidden" : "visible")};
`

const StickyButtonWrapper = styled(ButtonWrapper)`
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 1.7rem 2rem;
  /*   button {
    right: 2rem;
    left: 2rem;
  } */
  background-color: white;
  z-index: 99;
  @media (min-width: ${({ theme }: any) => theme.breakpoint.md}px) {
    display: none;
    pointer-events: none;
    * {
      display: none !important;
    }
  }
`

const LowStock = styled.div<any>`
  margin: 0.7rem 0 0;
  text-align: center;
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: ${({ theme }) => theme.color.grey};
  @media (max-width: ${({ theme }: any) => theme.breakpoint.md}px) {
    ${({ hideOnStickyMobile }: any) =>
      hideOnStickyMobile &&
      `
      display: none;
    `}
  }

  span {
    font-weight: 700;
  }
`
