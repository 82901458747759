import { FC, useState } from "react"
import styled from "styled-components"
import Link from "next/link"
import Image from "next/image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import type {
  Document,
  EntryHyperlink,
  Hyperlink,
  EntryLinkBlock,
  AssetLinkBlock,
} from "@contentful/rich-text-types"
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"
import { TextBlock as T } from "@lib/types"

import { useTracking } from "@hooks/index"

import { TextBlock } from "@components/common"
import { AccordionItem, InfoPageMedia } from "@components/infoPage"
import { Button, Modal } from "@components/ui"
import { PlaceholderThumbnail } from "@components/account"

interface ModalButtonProps {
  content: T
}

const ModalButton: FC<ModalButtonProps> = ({ children, content }) => {
  const [open, setOpen] = useState(false)
  const { track } = useTracking()
  return (
    <>
      <InlineButton
        onClick={() => {
          setOpen(true)
          track("Text Modal Open")
        }}
      >
        {children}
      </InlineButton>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false)
          track("Text Modal Closed")
        }}
      >
        <TextWrapper>
          <TextBlock textBlock={content} />
        </TextWrapper>
      </Modal>
    </>
  )
}

const TextWrapper = styled.div`
  max-width: 45rem;
`
const renderEntry = (
  node: any,
  entries: any,
  type: "inline" | "block" | "hyperlink"
) => {
  if (!entries || !entries[type]) return

  const {
    data: {
      target: {
        sys: { id },
      },
    },
  } = node

  const data = entries[type].filter(
    ({ sys }: { sys: { id: string } }) => sys.id === id
  )[0]
  
  if (type === "block") {
    if (data?.__typename === "Button") {
      return (
        <ButtonWrapper className="rich-text__button-wrapper">
          <Button button={data}>{data.title}</Button>
        </ButtonWrapper>
      )
    }

    if (data?.__typename === "AccordionItem") {
      return <AccordionItem label={data.label} richText={data.richText} />
    }

    if (data?.__typename === "InfoPageMedia") {
      return (
        <InfoPageMedia
          title={data.title}
          thumbnails={data.blocksCollection.items}
        />
      )
    }

    if (data?.__typename === "CustomHtml") {
      return (
        <div>
          <div dangerouslySetInnerHTML={{__html: data.html}}></div>
        </div>
      )
    }

    if (data?.__typename === "Collection") {
      return <PlaceholderThumbnail data={data} />
    }
  }

  if (type === "inline") {
    if (data?.entry?.__typename === "TextBlock") {
      return <ModalButton content={data.entry}>{data.title}</ModalButton>
    }

    if (data?.__typename === "Link") {
      return (
        <Link href={`/${data.entry.slug}`} passHref>
          <InlineLink>{data.title}</InlineLink>
        </Link>
      )
    }
  }

  if (type === "hyperlink") {
    return data.__typename === "InfoPage" ? (
      <Link href={`/pages/${data.slug}`} passHref>
        <InlineLink>{node.content[0].value}</InlineLink>
      </Link>
    ) : data.__typename === "Product" ? (
      <Link href={`/products/${data.slug}`} passHref>
        <InlineLink>{node.content[0].value}</InlineLink>
      </Link>
    ) : (
      <Link href={`/${data.slug}`} passHref>
        <InlineLink>{node.content[0].value}</InlineLink>
      </Link>
    )
  }
}

const ButtonWrapper = styled.div`
  margin: 3.2rem 0;
`

const InlineLink = styled.a`
  text-decoration: underline;
`

const InlineButton = styled.button`
  text-decoration: underline;
`

const phoneNumberPattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/

const renderHyperLinkNode = ({ content, data }: Hyperlink) => {
  if (phoneNumberPattern.test(data.uri)) {
    return <a href={`tel:${data.uri}`}>{content[0].value}</a>
  }

  if (data.uri.includes("@")) {
    return <a href={`mailto:${data.uri}`}>{content[0].value}</a>
  }

  return <a href={data.uri}>{content[0].value}</a>
}

const renderAsset = (node: AssetLinkBlock, assets: any) => {
  const {
    data: {
      target: {
        sys: { id },
      },
    },
  } = node

  const asset = assets.block.filter(
    ({ sys }: { sys: { id: string } }) => sys.id === id
  )[0]

  return (
    <ImageWrapper>
      <Image
        priority={true} 
        src={asset.url}
        alt={asset.description}
        width={asset.width}
        height={asset.height}
        layout="intrinsic"
      />
    </ImageWrapper>
  )
}

const ImageWrapper = styled.div`
  margin: 0 0 2rem;
`

const options: any = (links: any) => {
  if(links) return ({
  renderMark: {
    [MARKS.BOLD]: (text: string) => <b>{text}</b>,
    [MARKS.UNDERLINE]: (text: string) => <u>{text}</u>,
    [MARKS.ITALIC]: (text: string) => <i>{text}</i>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node: Hyperlink) => renderHyperLinkNode(node),
    [INLINES.ENTRY_HYPERLINK]: (node: EntryHyperlink) => {
      return renderEntry(node, links?.entries, "hyperlink")
    },
    [INLINES.EMBEDDED_ENTRY]: (node: EntryLinkBlock) => {
      return renderEntry(node, links.entries, "inline")
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: AssetLinkBlock) => {
      return renderAsset(node, links.assets)
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: EntryLinkBlock) => {
      return renderEntry(node, links.entries, "block")
    },
  },
})}

interface Props {
  richText: {
    links?: {}
    json: Document
  }
}

export const RichText: FC<Props> = ({ richText }) => {
  return (
    <RichTextWrapper>
      {documentToReactComponents(richText?.json, options(richText?.links))}
    </RichTextWrapper>
  )
}
const RichTextWrapper = styled.div`
  ul {
    list-style: unset;
    margin-left: 2rem;
  }
  ol {
    padding-left: 2rem;
  }
  li > p {
    margin: 0.5rem;
  }
`
