import { FC } from "react"
import styled from "styled-components"
import { ContentBlock, Color } from "@lib/types"

import { SectionWithTitle, CommunityThumbnail } from "@components/ui"
import Slider from '@components/ui/Slider'

interface Props {
  data: {
    __typename: string
    preTitle: string
    title: string
    backgroundColor: Color
    blocksCollection: {
      items: ContentBlock[]
    }
  }
}

export const Instagram: FC<Props> = ({ data }) => {
  const {
    title,
    preTitle,
    backgroundColor,
    blocksCollection: { items },
  } = data

  return (
    <SectionWithTitle
      title={title}
      preTitle={preTitle}
      backgroundColor={backgroundColor}
      containerMaxWidth="144rem"
    >
      <InstagramSliderWrapper>
        <Slider
          displayArrows
          slides={[...items, ...items]}
          SlideComponent={CommunityThumbnail}
          config={{
            slidesPerView: 1,
            breakpoints: {
              "(min-width: 768px)": {
                slidesPerView: 3,
              },
            },
          }}
        />
      </InstagramSliderWrapper>
    </SectionWithTitle>
  )
}

export const InstagramSliderWrapper = styled.div`
  display: flex;
  justify-content: center;
`
